import resized from "@/javascript/components/events/resized"
import { fadeOut, fadeIn } from "@/javascript/components/animations/fade"
import Loader from "@/javascript/components/loader"

const obfuscateIds = (element) => {
  const random = Math.random().toString(36).substring(7)
  element.querySelectorAll('[id]').forEach((elem) => {
    elem.id = `${elem.id}-${random}`
  })
}

export function replaceWith (element, newHtml, opts = {}, callback = null) {
  if(typeof(opts) == 'function') {
    callback = opts
    opts = {}
  }

  let temp = document.createElement('div')
  temp.innerHTML = newHtml
  let content = temp.firstChild
  content.style.setProperty('display', 'none' )

  obfuscateIds(element)
  element.parentNode.insertBefore(content, element)

  return new Loader(content).all()
    .then(() => {
      if(callback) {
        callback(content)
      }
    })
    .then(() => {
      if(opts.fade) {
        content.style.setProperty('opacity', 0)
        fadeOut(element, 300).then(() => {
          element.remove()
          fadeIn(content, 300)
        })
      } else {
        content.style.removeProperty('display')
        element.remove()
      }
    })
    .then(() => {
      resized(content)
      return content
    })
}

export function appendHTML (element, newHtml, callback = null) {
  let content = document.createElement('div')
  content.innerHTML = newHtml
  content.style.setProperty('display', 'none' )
  element.appendChild(content)

  return new Loader(content).all()
    .then(() => {
      if(callback) {
        callback(content)
      }
      content.style.removeProperty('display')
      resized(content)
      return content
    })
}

